import React from 'react'
// import { Link } from 'gatsby-link'
// import styled from 'styled-components'

import Layout from '../components/layout'

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <div />
  </Layout>
)

export default IndexPage
