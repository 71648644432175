import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

const BgImage = styled(Img)`
  z-index: -5;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-size: 'cover';
  filter: brightness(110%);
}
`

const ImageBackground = () => (
  <StaticQuery
    query={graphql`
      query ImageQuery {
        image: imageSharp(original: { src: { regex: "/background/" } }) {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    `}
    render={data => (
      <>
        <BgImage
          style={{
            position: 'absolute',
          }}
          fluid={data.image.fluid}
          alt="background cloud image"
        />
      </>
    )}
  />
)

export default ImageBackground
