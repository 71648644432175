import React from 'react'
// import { Link } from 'gatsby-link'
// import styled from 'styled-components'
import Responsive from 'react-responsive'

import logo from '../images/logo-with-border.svg'
import slogan from '../images/slogan.svg'

const logoDivStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'fixed',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
}

const imgStyles = {
  margin: '0.8rem',
}

const responsiveImgStyles = {
  margin: '1.25vw',
}

const LogoAndSloganOriginal = () => (
  <>
    <img style={imgStyles} src={logo} alt="Tornadic" />
    <img style={imgStyles} src={slogan} alt="we craft apps" />
  </>
)

const LogoAndSloganResponsive = () => (
  <>
    <img
      style={{ ...responsiveImgStyles, width: '70vw' }}
      src={logo}
      alt="Tornadic"
    />
    <img
      style={{ ...responsiveImgStyles, width: '30vw' }}
      src={slogan}
      alt="we craft apps"
    />
  </>
)

const Big = props => <Responsive {...props} minWidth={1050} />
const Small = props => <Responsive {...props} maxWidth={1049} />

const LogoAndSlogan = () => (
  <div style={logoDivStyles}>
    <Big>
      <LogoAndSloganOriginal />
    </Big>
    <Small>
      <LogoAndSloganResponsive />
    </Small>
  </div>
)

export default LogoAndSlogan
