import React from 'react'
// import { graphql } from 'gatsby'
// import { Link } from 'gatsby-link'
import ImageBackground from './ImageBackground'
import LogoAndSlogan from './LogoAndSlogan'

const Header = props => (
  <>
    <ImageBackground />
    {props.isRoot && <LogoAndSlogan />}
  </>
)

export default Header
